// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-assistencia-certificada-js": () => import("./../../../src/pages/assistencia-certificada.js" /* webpackChunkName: "component---src-pages-assistencia-certificada-js" */),
  "component---src-pages-campanha-js": () => import("./../../../src/pages/campanha.js" /* webpackChunkName: "component---src-pages-campanha-js" */),
  "component---src-pages-contactos-js": () => import("./../../../src/pages/contactos.js" /* webpackChunkName: "component---src-pages-contactos-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-intermediacao-de-credito-js": () => import("./../../../src/pages/intermediacao-de-credito.js" /* webpackChunkName: "component---src-pages-intermediacao-de-credito-js" */),
  "component---src-pages-marcacao-assistencia-js": () => import("./../../../src/pages/marcacao-assistencia.js" /* webpackChunkName: "component---src-pages-marcacao-assistencia-js" */),
  "component---src-pages-motorrad-js": () => import("./../../../src/pages/motorrad.js" /* webpackChunkName: "component---src-pages-motorrad-js" */),
  "component---src-pages-noticias-js": () => import("./../../../src/pages/noticias.js" /* webpackChunkName: "component---src-pages-noticias-js" */),
  "component---src-pages-sobre-js": () => import("./../../../src/pages/sobre.js" /* webpackChunkName: "component---src-pages-sobre-js" */),
  "component---src-pages-stand-js": () => import("./../../../src/pages/stand.js" /* webpackChunkName: "component---src-pages-stand-js" */),
  "component---src-pages-termos-politica-privacidade-js": () => import("./../../../src/pages/termos-politica-privacidade.js" /* webpackChunkName: "component---src-pages-termos-politica-privacidade-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-servicos-js": () => import("./../../../src/templates/servicos.js" /* webpackChunkName: "component---src-templates-servicos-js" */),
  "component---src-templates-stand-single-car-js": () => import("./../../../src/templates/standSingleCar.js" /* webpackChunkName: "component---src-templates-stand-single-car-js" */)
}

